import React from "react";
// import newsletter1 from '../2013_NIC_National_Highlights_Fall_2013_Newsletter.pdf';
// import newsletter2 from '../2015_NIC_Highlights_Cooper_Valuation_Group_October_2015_Newsletter.pdf';
// import newsletter3 from '../2016_NIC_Fall_Conference_Highlights_Cooper_Valuation_Group.pdf';
// import newsletter4 from '../HUD Lean Update_Cooper Valuation Group - Spring 2013 Newsletter.pdf';
// import newsletter5 from '../HUD_Lean_Update_Cooper_Valuation_Group_Fall_2011_Newsletter.pdf';
// import newsletter6 from '../Midwest_Economy_Skilled_Nursing_Facility_Trends_Cooper_Valuation Group_Summer_2011_Newsletter.pdf';

const Newsletters = () => {
    const newslettersList = [
        {title:'NIC Fall Conference Highlights – October 2016',link:'/2016_NIC_Fall_Conference_Highlights_Cooper_Valuation_Group.pdf'},
        {title:'NIC National Conference Highlights - October 2015', link:'/2015_NIC_Highlights_Cooper_Valuation_Group_October_2015_Newsletter.pdf'},
        {title:'NIC National Conference Highlights - October 2013', link:'/2013_NIC_National_Highlights_Fall_2013_Newsletter.pdf'},
        {title:'HUD Lean Seminar – Philadelphia - March 2013', link:'/2013_NIC_National_Highlights_Fall_2013_Newsletter.pdf'},
        {title:'HUD Lean Seminar – Chicago September 2011', link:'/HUD_Lean_Update_Cooper_Valuation_Group_Fall_2011_Newsletter.pdf'},
        {title:'Summer 2011 – Midwest Market Update', link:'/Midwest_Economy_Skilled_Nursing_Facility_Trends_Cooper_Valuation Group_Summer_2011_Newsletter.pdf'}
    ];

    return (
        <div className="flex flex-col w-full mx-auto pb-44 pt-4 px-4 sm:px-8 bg-third shadow-md text-white">
            <div className="flex flex-col mb-4">
                <div className="w-60 border-b border-white mx-auto"></div>
                <h2 className="text-4xl font-bold text-center whitespace-nowrap mb-2 mt-2"> CVG Newsletters </h2>
                <div className="w-60 border-b border-white mx-auto mb-4"></div>
            </div>
            <div className="bg-white p-4 rounded-lg shadow-md text-gray-800 mb-8">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-2">
                    {newslettersList.map((newsletter, index) => (
                        <div key={index} className="flex flex-col justify-center items-center p-12 bg-nongreen rounded-lg shadow-md space-y-2">
                            <a href={newsletter.link} target="_blank" rel="noopener noreferrer" className="text-center text-blue-600 transform hover:scale-105 hover:text-third transition duration-300 ease-in-out">{newsletter.title}</a>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
};

export default Newsletters;






